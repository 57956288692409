import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import DinaraApp from './DinaraApp';
import './index.css';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBOlInVBBq01Y4vcVkLDbczvNiHFhf7ge0",
  authDomain: "buscador-de-cursos-unal.firebaseapp.com",
  projectId: "buscador-de-cursos-unal",
  storageBucket: "buscador-de-cursos-unal.appspot.com",
  messagingSenderId: "163541585960",
  appId: "1:163541585960:web:0aee7bbd5321dcd62ea51d",
  measurementId: "G-3KSWZ25GBY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <BrowserRouter>
        <DinaraApp />
     </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
