import { Routes, Route } from 'react-router-dom';
import CoursesSearch from '../components/CoursesSearch';
import CourseDetails from '../components/CourseDetails';
import { ProtectedRoute, ProtectedRouteByRole } from './ProtectedRoute';
import Roles from '../enums/Roles.enum';
import Shell from '../layouts/Shell';
import { Dashboard } from '../components/dashboard/Dashboard';
import { PublicLayout } from '../layouts/publicLayout';
import { PublicRoute } from './PublicRoute';
import Login from '../components/auth/Login';
import ResetPassword from '../components/auth/ResetPassword';

export const AppRouter = () => {
  return (
    <Routes>
      <Route path='course-details' element={<PublicLayout content={ <CourseDetails /> }/>} />
      <Route element={<PublicRoute />}>   
        <Route path="login" element={<PublicLayout content={ <Login /> }/>} />
        <Route path="reset-password" element={<PublicLayout content={ <ResetPassword /> }/>} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route element={ <ProtectedRouteByRole rolesNotAllowed={ [ ] } /> } >
          <Route path="dashboard" element={<Shell content={<Dashboard />} />} />
        </Route>
      </Route>
      <Route path='/*' element={<PublicLayout content={ <CoursesSearch /> }/>} />
    </Routes>
  );
};
