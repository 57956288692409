import { ThemeProvider, CssBaseline } from "@mui/material";
import { principalTheme } from "./principalTheme";

export const AppTheme = ({ children }) => {
  return (
    <ThemeProvider theme={principalTheme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
