import {
  Alert,
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useAuth } from '../../providers/AuthProvider';
import { useNavigate } from 'react-router-dom';
import createAxiosInstance from '../../helpers/AxiosConfig';

export const LoginForm = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailTouched, setEmailTouched] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorResponse, setErrorResponse] = useState({
    message: '',
    type: 'error',
  });
  const [formType, setFormType] = useState('login');

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorResponse({ message: '', type: 'error' });
    if (formType === 'recoverPassword') {
      handleRecoverPassword();
    } else {
      handleLogin();
    }
  };

  const handleLogin = async () => {
    setIsLoading(true);
    const request = {
      email,
      password,
    };

    try {
      const { data } = await createAxiosInstance().post(`/auth/login`, request);
      if (data.token) {
        auth.saveUser(data);
        navigate('/dashboard', {
          replace: true,
        });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setErrorResponse({
        message:
          error.response &&
          (error.response.status === 401 || error.statusCode === 401)
            ? 'Datos incorrectos'
            : 'Error del servidor, intentalo más tarde',
        type: 'error',
      });
    }
  };

  const handleRecoverPassword = async () => {
    setIsLoading(true);
    const request = {
      email,
    };
    try {
      const { data } = await createAxiosInstance().post(`/auth/password-recovery`, request);
      setErrorResponse({ message: data.message, type: 'success' });
      setFormType('login');
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setErrorResponse({
        message:
          error.response &&
          (error.response.status === 401 || error.statusCode === 401)
            ? error.response.data?.message
            : 'Error del servidor, intentalo más tarde',
        type: 'error',
      });
    }
  };

  return (
    <Box>
      <Typography gutterBottom variant='h5' component='h2' sx={{ mb: 3 }}>
        {formType === 'login'
          ? 'Iniciar sesión'
          : 'Olvidaste tu contraseña'}
      </Typography>
      <Box
        component='form'
        sx={{
          '& .MuiTextField-root, & .MuiFormControl-root': { mb: 3 },
        }}
        noValidate
        onSubmit={handleSubmit}
        autoComplete='off'
      >
        {errorResponse && errorResponse.message && (
          <Alert severity={errorResponse.type} sx={{ mb: 3 }}>
            {errorResponse.message}
          </Alert>
        )}
        <TextField
          required
          type='email'
          id='email'
          value={email}
          autoComplete='username'
          label='Correo eléctronico'
          onChange={(e) => setEmail(e.target.value)}
          onBlur={() => setEmailTouched(true)}
          error={emailTouched && !email}
          helperText={emailTouched && !email ? 'El correo es requerido' : ''}
          fullWidth
        />
        {formType === 'login' && (
          <FormControl variant='outlined' fullWidth required>
            <InputLabel htmlFor='password' error={passwordTouched && (!password || password.length < 6)}>
              Contraseña
            </InputLabel>
            <OutlinedInput
              id='password'
              autoComplete='current-password'
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='Alternar visibilidad de contraseña'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              value={password}
              error={passwordTouched && (!password || password.length < 6)}
              onChange={(e) => setPassword(e.target.value)}
              onBlur={() => setPasswordTouched(true)}
              label='Contraseña'
            />
            {passwordTouched && !password && (
              <FormHelperText error={passwordTouched && !password}>
                La contraseña es requerida
              </FormHelperText>
            )}

          {passwordTouched && password && password.length < 6 && (
            <FormHelperText error={true}>
              La contraseña debe ser de minimo 6 caracteres
            </FormHelperText>
          )}
            <Box textAlign='right'>
              <Button onClick={() => { setFormType('recoverPassword'); setErrorResponse({ message: '', type: 'error' }); }}>
                ¿Olvidaste tu contraseña?
              </Button>
            </Box>
          </FormControl>
        )}
        <Box sx={{ textAlign: 'center' }}>
          {formType === 'recoverPassword' ? (
            <Box
              sx={{ display: 'inline-flex', flexDirection: 'column', gap: 2 }}
            >
              <Button
                size='large'
                type='submit'
                variant='contained'
                disabled={(!email) || isLoading}
              >
                {isLoading ? 'Cargando...' : 'Reestablecer contraseña'}
              </Button>
              <Button onClick={() => { setFormType('login'); setErrorResponse({ message: '', type: 'error' }); }}>Iniciar sesión</Button>
            </Box>
          ) : (
            <Button
              size='large'
              type='submit'
              variant='contained'
              disabled={(!email || (!password || password.length < 6)) || isLoading}
            >
              {isLoading ? 'Cargando...' : 'Ingresar'}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};
