import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Pagination,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { localeText } from '../configs/config';
import createAxiosInstance from '../helpers/AxiosConfig';
import {
  DaysData,
  TimeZoneData,
  hideColumnsData,
} from './data/courseSearchData';
import './style.jsx/style.css';

const localizedTextsMap = {
  columnMenuUnsort: "Sin clasificar", // Personaliza según sea necesario
  columnMenuSortAsc: "Ordenar ascendente",
  columnMenuSortDesc: "Ordenar descendente",

  noRowsLabel: 'Sin filas',
  noResultsOverlayLabel: 'Resultados no encontrados',

  // Density selector toolbar button text
  toolbarDensity: 'Densidad',
  toolbarDensityLabel: 'Densidad',
  toolbarDensityCompact: 'Compacta',
  toolbarDensityStandard: 'Estándar',
  toolbarDensityComfortable: 'Cómoda',

  // Columns selector toolbar button text
  toolbarColumns: 'Columnas',
  toolbarColumnsLabel: 'Seleccionar columnas',

  // Filters toolbar button text
  toolbarFilters: 'Filtros',
  toolbarFiltersLabel: 'Mostrar filtros',
  toolbarFiltersTooltipHide: 'Ocultar filtros',
  toolbarFiltersTooltipShow: 'Mostrar filtros',
  toolbarFiltersTooltipActive: (count) =>
    count > 1 ? `${count} filtros activos` : `${count} filtro activo`,

  // Quick filter toolbar field
  toolbarQuickFilterPlaceholder: 'Buscar…',
  toolbarQuickFilterLabel: 'Buscar',
  toolbarQuickFilterDeleteIconLabel: 'Limpiar',

  // Export selector toolbar button text
  toolbarExport: 'Exportar',
  toolbarExportLabel: 'Exportar',
  toolbarExportCSV: 'Descargar como CSV',
  toolbarExportPrint: 'Imprimir',
  toolbarExportExcel: 'Descargar como Excel',

  // Columns management text
  columnsManagementSearchTitle: 'Buscar',
  columnsManagementNoColumns: 'Sin columnas',
  columnsManagementShowHideAllText: 'Mostrar/Ocultar todas',
  // columnsManagementReset: 'Reset',

  // Filter panel text
  filterPanelAddFilter: 'Agregar filtro',
  filterPanelRemoveAll: 'Remover todos',
  filterPanelDeleteIconLabel: 'Borrar',
  filterPanelLogicOperator: 'Operador lógico',
  filterPanelOperator: 'Operadores',
  filterPanelOperatorAnd: 'Y',
  filterPanelOperatorOr: 'O',
  filterPanelColumns: 'Columnas',
  filterPanelInputLabel: 'Valor',
  filterPanelInputPlaceholder: 'Valor de filtro',
  RowsPerPage: "Filas por página",

  // Filter operators text
  filterOperatorContains: 'contiene',
  filterOperatorEquals: 'es igual',
  filterOperatorStartsWith: 'comienza con',
  filterOperatorEndsWith: 'termina con',
  filterOperatorIs: 'es',
  filterOperatorNot: 'no es',
  filterOperatorAfter: 'es posterior',
  filterOperatorOnOrAfter: 'es en o posterior',
  filterOperatorBefore: 'es anterior',
  filterOperatorOnOrBefore: 'es en o anterior',
  filterOperatorIsEmpty: 'esta vacío',
  filterOperatorIsNotEmpty: 'no esta vacío',
  filterOperatorIsAnyOf: 'es cualquiera de',
  'filterOperator=': '=',
  'filterOperator!=': '!=',
  'filterOperator>': '>',
  'filterOperator>=': '>=',
  'filterOperator<': '<',
  'filterOperator<=': '<=',

  // Header filter operators text
  headerFilterOperatorContains: 'Contiene',
  headerFilterOperatorEquals: 'Es igual a',
  headerFilterOperatorStartsWith: 'Comienza con',
  headerFilterOperatorEndsWith: 'Termina con',
  headerFilterOperatorIs: 'Es',
  headerFilterOperatorNot: 'No es',
  headerFilterOperatorAfter: 'Esta después de',
  headerFilterOperatorOnOrAfter: 'Esta en o después de',
  headerFilterOperatorBefore: 'Esta antes de',
  headerFilterOperatorOnOrBefore: 'Esta en o antes de',
  headerFilterOperatorIsEmpty: 'Esta vacío',
  headerFilterOperatorIsNotEmpty: 'No esta vacío',
  headerFilterOperatorIsAnyOf: 'Es cualquiera de',
  'headerFilterOperator=': 'Es igual a',
  'headerFilterOperator!=': 'Es diferente a',
  'headerFilterOperator>': 'Es mayor que',
  'headerFilterOperator>=': 'Es mayor o igual que',
  'headerFilterOperator<': 'Es menor que',
  'headerFilterOperator<=': 'Es menor o igual que',

  // Filter values text
  filterValueAny: 'cualquiera',
  filterValueTrue: 'verdadero',
  filterValueFalse: 'falso',

  // Column menu text
  columnMenuLabel: 'Menú',
  columnMenuShowColumns: 'Mostrar columnas',
  columnMenuManageColumns: 'Administrar columnas',
  columnMenuFilter: 'Filtro',
  columnMenuHideColumn: 'Ocultar',
  columnMenuUnsort: 'Desordenar',
  columnMenuSortAsc: 'Ordenar ASC',
  columnMenuSortDesc: 'Ordenar DESC',

  // Column header text
  columnHeaderFiltersTooltipActive: (count) =>
    count > 1 ? `${count} filtros activos` : `${count} filtro activo`,
  columnHeaderFiltersLabel: 'Mostrar filtros',
  columnHeaderSortIconLabel: 'Ordenar',

  // Rows selected footer text
  footerRowSelected: (count) =>
    count > 1
      ? `${count.toLocaleString()} filas seleccionadas`
      : `${count.toLocaleString()} fila seleccionada`,

  // Total row amount footer text
  footerTotalRows: 'Filas Totales:',

  // Total visible row amount footer text
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,

  // Checkbox selection text
  checkboxSelectionHeaderName: 'Seleccionar casilla',
  checkboxSelectionSelectAllRows: 'Seleccionar todas las filas',
  checkboxSelectionUnselectAllRows: 'Deseleccionar todas las filas',
  checkboxSelectionSelectRow: 'Seleccionar fila',
  checkboxSelectionUnselectRow: 'Deseleccionar fila',

  // Boolean cell text
  booleanCellTrueLabel: 'si',
  booleanCellFalseLabel: 'no',

  // Actions cell more text
  actionsCellMore: 'más',

  // Column pinning text
  pinToLeft: 'Anclar a la izquierda',
  pinToRight: 'Anclar a la derecha',
  unpin: 'Desanclar',

  // Tree Data
  treeDataGroupingHeaderName: 'Grupo',
  treeDataExpand: 'mostrar hijos',
  treeDataCollapse: 'ocultar hijos',

  // Grouping columns
  groupingColumnHeaderName: 'Grupo',
  groupColumn: (name) => `Agrupar por ${name}`,
  unGroupColumn: (name) => `No agrupar por ${name}`,

  // Master/detail
  detailPanelToggle: 'Alternar detalle',
  expandDetailPanel: 'Expandir',
  collapseDetailPanel: 'Contraer',

  // Row reordering text
  rowReorderingHeaderName: 'Reordenar filas',

  // Aggregation
  aggregationMenuItemHeader: 'Agregación',
  aggregationFunctionLabelSum: 'sum',
  aggregationFunctionLabelAvg: 'avg',
  aggregationFunctionLabelMin: 'min',
  aggregationFunctionLabelMax: 'max',
  aggregationFunctionLabelSize: 'tamaño',


};

let data = {
  level: '',
  campus: '',
  faculty: '',
  studyPlan: '',
};

/**
 * Funciona para el guardado de los fields opcionales en el storage
 */
let optionalsFields = {
  num_creditos: '',
  subject_name: '',
  typology: '',
  days: [],
  timeZones: [],
  subject_code: '',
  num_groups: '',
  num_quotas: '',
};

let facultiesInStorage = [];
let studyPlansInStorage = [];
let tipologiesInStorage = [];

export default function CoursesSearch() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [campuses, setCampuses] = useState([]);
  const [faculties, setFaculties] = useState([]);
  const [studyPlans, setStudyPlans] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [resultsRequiredOptions, setResultsRequiredOptions] = useState([]);
  const [results, setResults] = useState([]);

  const [level, setLevel] = useState('');
  const [isTouchedLevel, setIsTouchedLevel] = useState(false);
  const [campus, setCampus] = useState('');
  const [isTouchedCampus, setIsTouchedCampus] = useState(false);
  const [faculty, setFaculty] = useState('');
  const [isTouchedFaculty, setIsTouchedFaculty] = useState(false);
  const [studyPlan, setStudyPlan] = useState('');
  const [isTouchedStudyPlan, setIsTouchedStudyPlan] = useState(false);
  const [tipologies, setTipologies] = useState([]);
  const [optionalForm, setOptionalForm] = useState({
    num_creditos: '',
    subject_name: '',
    typology: '',
    days: [],
    timeZones: [],
    subject_code: '',
    num_groups: '',
    num_quotas: '',
  });

  const [levelData, setLevelData] = useState([]);
  const [campusesData, setCampusesData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [filteredHeaders, setFilteredHeaders] = useState([]);
  const [hideColumns, setHideColumns] = useState([]);

  const [appAlert, setAppAlert] = useState({
    message: '',
    type: 'error',
    errors: [],
  });

  // pagination
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100); // Cuántos elementos por página
  const [itemsCount, setItemsCount] = useState(0); // Total de elementos

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchCampuses();
        await fetchLevels();
      } catch (error) {
        setAppAlert({
          type: 'error',
          message: getErrorMessage(error),
          errors: getErrorsList(error),
        });
      }
    };
    fetchData();
    const headersData = [
      {
        field: 'CODIGO_ASIGNATURA',
        headerName: 'Código',
        minWidth: 80,
        flex: 1,
      },
      {
        field: 'NOMBREASIGNATURA',
        headerName: 'Nombre asignatura',
        minWidth: 550,
        flex: 1,
      },
      {
        field: 'NUM_CREDITOS',
        headerName: 'Creditos',
        minWidth: 50,
        flex: 1,
      },
      {
        field: 'TIPOLOGIA',
        headerName: 'Tipologia',
        minWidth: 250,
        flex: 1,
      },
      {
        field: 'NUMERODEGRUPOS',
        headerName: 'Número de grupos',
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'NUMERODECUPOS',
        headerName: 'Número de cupos',
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'actions',
        headerName: 'Acciones',
        minWidth: 150,
        flex: 1,
        renderCell: (params) => (
          <Button onClick={() => handleButtonClick(params.row)}>Ver</Button>
        ),
      },
    ];
    setHeaders(headersData);
    setFilteredHeaders(headersData);
  }, []);

  const getErrorMessage = (error) => {
    return !error.response ||
      error.response.status === 500 ||
      error.statusCode === 500
      ? 'Error del servidor, intentalo más tarde'
      : 'Error al obtener los datos';
  };

  const getErrorsList = (error) => {
    return error.response && error.response.data && error.response.data.message
      ? Array.isArray(error.response.data.message)
        ? error.response.data.message
        : [error.response.data.message]
      : [];
  };

  const initAppAlert = () => {
    setAppAlert({
      type: 'error',
      message: '',
      errors: [],
    });
  };

  const fetchStudyPlans = async (facultad) => {
    try {
      initAppAlert();
      setIsLoading(true);
      const request = {
        nivel: level,
        sede: campus,
        facultad: facultad || faculty,
      };
      const { data } = await createAxiosInstance().get(
        `/planestudiodisponible/filtro-planes-estudio/?` +
          new URLSearchParams(request)
      );
      if (Array.isArray(data) && data.length > 0) {
        const rpt = data.filter((item) => item);
        setStudyPlans(rpt);
        studyPlansInStorage = rpt;
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setAppAlert({
        type: 'error',
        message: getErrorMessage(error),
        errors: getErrorsList(error),
      });
    }
  };

  const fetchLevels = async () => {
    try {
      initAppAlert();
      setIsLoading(true);
      const { data } = await createAxiosInstance().get(`/nivel`);
      if (Array.isArray(data)) {
        setLevelData(data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setAppAlert({
        type: 'error',
        message: getErrorMessage(error),
        errors: getErrorsList(error),
      });
    }
  };
  const fetchCampuses = async () => {
    try {
      initAppAlert();
      setIsLoading(true);
      const { data } = await createAxiosInstance().get(`/sedes`);
      setCampusesData(data);
      fillFilterForm(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setAppAlert({
        type: 'error',
        message: getErrorMessage(error),
        errors: getErrorsList(error),
      });
    }
  };

  const fetchFaculties = async (campus, level) => {
    try {
      initAppAlert();
      setIsLoading(true);
      const { data } = await createAxiosInstance().get(
        `/buscadorcursos/facultades/?sede=${campus}&nivel=${level}`
      );
      setFaculties(data);
      facultiesInStorage = data;
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setAppAlert({
        type: 'error',
        message: getErrorMessage(error),
        errors: getErrorsList(error),
      });
    }
  };

  const fetchTipologias = async () => {
    setIsLoading(true);
    try {
      const axiosInstance = createAxiosInstance();
      const request = {
        nivel: data.level,
        sede: data.campus,
        planEstudio: data.studyPlan,
      };
      const response = await axiosInstance.get(
        `/buscadorcursos/tipologias?${new URLSearchParams(request)}`
      );
      const rpt = response?.data;
      if (Array.isArray(rpt)) {
        setTipologies(rpt);
        tipologiesInStorage = rpt;
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setAppAlert({
        type: 'error',
        message: getErrorMessage(error),
        errors: getErrorsList(error),
      });
    }
  };

  const getResultsMapper = (results) => {
    return results.map((item) => {
      return {
        ...item,
        id: item.IDBUSCADORCURSO,
      };
    });
  };

  /**
   *
   * Filtro de acuerdo al nivel de estudio
   */
  const handleLevel = (level) => {
    data['level'] = level;
    handleShowResults();
    const campuses = campusesData.map((campus) => campus.NOMBRE);
    setCampuses(campuses);
  };

  /**
   *
   * Filtro de acuerdo a la sede
   */
  const handleCampus = (campus) => {
    data['campus'] = campus;
    handleShowResults();
    fetchFaculties(campus, level);
  };

  const handleFaculty = (faculty) => {
    data['faculty'] = faculty;
    handleShowResults();
    fetchStudyPlans(faculty);
  };

  const handleStudyPlan = (studyPlan) => {
    data['studyPlan'] = studyPlan;
    handleShowResults();
  };

  const isValidShowResults = () => {
    return data.level && data.campus && data.faculty && data.studyPlan;
  };

  const removerAccents = (cadena) => {
    return cadena.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };

  const sendRequest = async (page, pageSizeData = pageSize) => {
    try {
      initAppAlert();
      setIsLoading(true);

      // Crear un objeto request dinámico sin incluir campos opcionales vacíos
      const request = {
        nivel: data.level,
        sede: data.campus,
        facultad: data.faculty,
        planEstudio: data.studyPlan,
        page,
        limit: pageSizeData,
      };

      // Agregar solo los campos opcionales que tienen valor
      if (optionalsFields?.subject_name) {
        request.nombre_asignatura = optionalsFields.subject_name;
      }
      if (optionalsFields?.typology) {
        request.tipologia = optionalsFields.typology;
      }
      if (optionalsFields?.days?.length > 0) {
        request.dias = removerAccents(optionalsFields.days.join(','));
      }
      if (optionalsFields?.num_creditos) {
        request.numcreditos = optionalsFields.num_creditos;
      }
      if (optionalsFields?.timeZones?.length > 0) {
        request.franja_horaria = optionalsFields.timeZones.join(',');
      }
      if (optionalsFields?.subject_code) {
        request.codigo_asignatura = optionalsFields.subject_code;
      }
      if (optionalsFields?.num_groups) {
        request.numgrupos = optionalsFields.num_groups;
      }
      if (optionalsFields?.num_quotas) {
        request.numcupos = optionalsFields.num_quotas;
      }

      // Enviar solo los parámetros que tienen valor
      const response = await createAxiosInstance().get(
        `/buscadorcursos/busqueda/primernivel2/?${new URLSearchParams(request)}`
      );

      const searchData = response.data.data;

      const mapperData = getResultsMapper(searchData);
      setResultsRequiredOptions(mapperData);

      setResults(mapperData);
      setItemsCount(response.data.totalRecords);

      setShowResults(true);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setAppAlert({
        type: 'error',
        message: getErrorMessage(error),
        errors: getErrorsList(error),
      });
    }
  };

  const handleShowResults = () => {
    if (isValidShowResults()) {
      setPage(1);
      sendRequest(1);
    } else {
      setShowResults(false);
    }
  };

  const handleBlurLevel = () => {
    setIsTouchedLevel(true);
  };

  const handleBlurCampus = () => {
    setIsTouchedCampus(true);
  };

  const handleBlurFaculty = () => {
    setIsTouchedFaculty(true);
  };

  const handleBlurStudyPlan = () => {
    setIsTouchedStudyPlan(true);
  };

  const handleChangeLevel = (event) => {
    setLevel(event.target.value);
    // reset
    data = {
      level: '',
      campus: '',
      faculty: '',
      studyPlan: '',
    };
    setCampuses([]);
    setCampus('');
    setFaculties([]);
    setFaculty('');
    setStudyPlans([]);
    studyPlansInStorage = [];
    facultiesInStorage = [];
    tipologiesInStorage = [];
    setStudyPlan('');
    resetOptionalForm();
    const newLevel = event.target.value;
    setLevel(newLevel);
    handleLevel(newLevel);
  };

  const handleChangeCampus = (value) => {
    setCampus(value);
    // reset
    data = {
      ...data,
      faculty: '',
      studyPlan: '',
    };
    setFaculties([]);
    setFaculty('');
    setStudyPlans([]);
    studyPlansInStorage = [];
    facultiesInStorage = [];
    tipologiesInStorage = [];
    setStudyPlan('');
    resetOptionalForm();

    handleCampus(value);
  };

  const handleChangeFaculty = (value) => {
    setFaculty(value);
    // reset
    data = {
      ...data,
      studyPlan: '',
    };
    setStudyPlans([]);
    studyPlansInStorage = [];
    tipologiesInStorage = [];
    setStudyPlan('');
    resetOptionalForm();
    handleFaculty(value);
  };

  const handleChangeStudyPlan = (value) => {
    resetOptionalForm();
    setStudyPlan(value);
    handleStudyPlan(value);
    fetchTipologias();
  };

  const resetOptionalForm = () => {
    const data = {
      num_creditos: '',
      typology: '',
      days: [],
      timeZones: [],
      subject_name: '',
      subject_code: '',
      num_groups: '',
      num_quotas: '',
    };
    setOptionalForm(data);
    optionalsFields = data;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setOptionalForm((prevFormData) => {
      const form = { ...prevFormData };

      if (name === 'num_creditos') {
        const formattedValue = value.replace(/\D/g, ''); // Elimina caracteres no numéricos

        // Solo actualiza el campo si cumple con los requisitos de validación
        if (
          formattedValue === '' ||
          (formattedValue.length <= 2 && parseInt(formattedValue, 10) <= 100)
        ) {
          form[name] = formattedValue;
        }
      } else {
        form[name] = value; // Para cualquier otro campo
      }

      optionalsFields = form;

      return form; // Devuelve el nuevo objeto form
    });
  };

  const handleOnSearch = () => {
    setPage(1);
    sendRequest(1);
  };

  const handleButtonClick = (row) => {
    handleSetDataInSessionStorage();
    navigate(`/course-details/`, {
      state: {
        course: row,
      },
    });
  };

  const handleSetDataInSessionStorage = () => {
    sessionStorage.setItem(
      'filterData',
      JSON.stringify({
        requiredFields: data,
        optionalForm: optionalsFields,
        faculties: facultiesInStorage,
        studyPlans: studyPlansInStorage,
        tipologies: tipologiesInStorage,
      })
    );
  };

  const handleRemoveDataInSessionStorage = () => {
    sessionStorage.removeItem('filterData');
  };

  const getDataFromSessionStorage = () => {
    return sessionStorage.getItem('filterData');
  };

  const fillFilterForm = (campusesData) => {
    const sessionStorageData = getDataFromSessionStorage();
    if (sessionStorageData) {
      const parsedData = JSON.parse(sessionStorageData);

      data = parsedData?.requiredFields;
      optionalsFields = parsedData?.optionalForm;

      if (data && data.level && data.campus && data.faculty && data.studyPlan) {
        const campuses = campusesData.map((campus) => campus.NOMBRE);
        setCampuses(campuses);
        setFaculties(parsedData.faculties);
        setStudyPlans(parsedData.studyPlans);
        setTipologies(parsedData.tipologies);
        setLevel(data.level);
        setCampus(data.campus);
        setFaculty(data.faculty);
        setStudyPlan(data.studyPlan);
        setOptionalForm(parsedData.optionalForm);
        sendRequest(page);
      }
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [isColumnMenuOpen, setIsColumnMenuOpen] = useState(false);

  const handleHideColumnsChange = (event) => {
    const { value } = event.target;
    setHideColumns(value);
    const filter = headers.filter((header) => {
      const isValid = value.some((item) => item === header.field);
      return !isValid;
    });
    setFilteredHeaders(filter);
  };

  const getHideColumnsSelected = (selected) => {
    const res = [];
    hideColumnsData.forEach((column) => {
      const isValid = selected.some((s) => s === column.id);
      if (isValid) {
        res.push(column.name);
      }
    });
    return res.join(', ');
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    setShowResults(false);
    sendRequest(value, pageSize);
  };

  const handleCleanFiltersClick = () => {
    handleRemoveDataInSessionStorage();
    window.location.reload();
  };

  return (
    <Box>
      {appAlert && appAlert.message && (
        <Alert severity={appAlert.type} sx={{ mb: 3, textAlign: 'left' }}>
          {appAlert.message}
        </Alert>
      )}
      <Box
        component='form'
        sx={{
          '& .MuiTextField-root': { width: '100%' },
          mb: 4,
        }}
        noValidate
        autoComplete='off'
      >
        <Box>
          <Box sx={{ display: 'flex', gap: '16px', alignItems: 'flex-start' }}>
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id='level'
                  name='nivel'
                  required
                  select
                  label='Nivel'
                  value={level}
                  error={isTouchedLevel && !level}
                  helperText={
                    isTouchedLevel && !level ? 'El campo es requerido' : ''
                  }
                  size='small'
                  variant="standard"
                  onBlur={handleBlurLevel}
                  onChange={handleChangeLevel}
                >
                  {levelData.length > 0 ? (
                    levelData.map((option) => (
                      <MenuItem key={option.ID_NIVEL} value={option.NIVEL}>
                        {option.NIVEL}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled value=''>
                      No hay opciones disponibles
                    </MenuItem>
                  )}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Autocomplete
                  disablePortal
                  id='campuses'
                  name='campuses'
                  noOptionsText='No hay opciones, seleccione el nivel'
                  options={campuses}
                  value={campus ? campus : null}
                  getOptionLabel={(option) => option}
                  getOptionKey={(option) => option}
                  sx={{ width: '100%' }}
                  isOptionEqualToValue={(option, value) => option === value}
                  onBlur={() => handleBlurCampus()}
                  onChange={(event, newInputValue) => {
                    handleChangeCampus(newInputValue);
                  }}
                  renderOption={(props, option) => (
                    <Box component='li' {...props} key={option}>
                      {option}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size='small'
                      variant="standard"
                      label='Sede'
                      required
                      error={isTouchedCampus && !campus}
                      helperText={
                        isTouchedCampus && !campus
                          ? 'El campo es requerido'
                          : ''
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Autocomplete
                  disablePortal
                  id='faculty'
                  name='faculty'
                  noOptionsText='No hay opciones, seleccione una sede que contenga facultades'
                  options={faculties}
                  value={faculty ? faculty : null}
                  getOptionLabel={(option) => option}
                  getOptionKey={(option) => option}
                  sx={{ width: '100%' }}
                  isOptionEqualToValue={(option, value) => option === value}
                  onBlur={() => handleBlurFaculty()}
                  onChange={(event, newInputValue) => {
                    handleChangeFaculty(newInputValue);
                  }}
                  renderOption={(props, option) => (
                    <Box component='li' {...props} key={option}>
                      {option}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size='small'
                      variant="standard"
                      label='Facultad'
                      required
                      error={isTouchedFaculty && !faculty}
                      helperText={
                        isTouchedFaculty && !faculty
                          ? 'El campo es requerido'
                          : ''
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Autocomplete
                  disablePortal
                  id='studyPlan'
                  name='studyPlan'
                  noOptionsText='No hay opciones, seleccione una facultad que contenga planes de estudio'
                  options={studyPlans}
                  value={studyPlan ? studyPlan : null}
                  getOptionLabel={(option) => option}
                  getOptionKey={(option) => option}
                  sx={{ width: '100%' }}
                  isOptionEqualToValue={(option, value) => option === value}
                  onBlur={() => handleBlurStudyPlan()}
                  onChange={(event, newInputValue) => {
                    handleChangeStudyPlan(newInputValue);
                  }}
                  renderOption={(props, option) => (
                    <Box component='li' {...props} key={option}>
                      {option}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size='small'
                      variant="standard"
                      label='Plan de estudio'
                      required
                      error={isTouchedStudyPlan && !studyPlan}
                      helperText={
                        isTouchedStudyPlan && !studyPlan
                          ? 'El plan de estudio es requerido'
                          : ''
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            {(level || campus || faculty || studyPlan) && (
              <IconButton
                aria-label='limpiar filtros'
                size='large'
                title='limpiar filtros'
                onClick={() => handleCleanFiltersClick()}
              >
                <CloseIcon fontSize='large' />
              </IconButton>
            )}
          </Box>
          {showResults && (
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='h7' gutterBottom component={'span'}>
                    Filtrar por:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth variant='standard'>
                    <InputLabel htmlFor='typology'>Tipologia</InputLabel>
                    <Select
                      labelId='typology-label'
                      name='typology'
                      value={optionalForm.typology}
                      onChange={handleChange}
                      fullWidth
                      label='Tipología'
                    >
                      <MenuItem value=''>
                        <em>Seleccione la tipología</em>
                      </MenuItem>
                      {tipologies.map((tipologia) => (
                        <MenuItem key={tipologia} value={tipologia}>
                          {tipologia}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    id='creditsNumber'
                    name='num_creditos'
                    label='Número de creditos'
                    value={optionalForm.num_creditos}
                    helperText=''
                    size='small'
                    variant="standard"
                    inputProps={{
                      maxLength: 3,
                      inputMode: 'numeric',
                    }}
                    onChange={handleChange}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel id='days'>Días</InputLabel>
                    <Select
                      labelId='days'
                      id='daysOptions'
                      multiple
                      name='days'
                      value={optionalForm.days}
                      onChange={handleChange}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={MenuProps}
                    >
                      {DaysData.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox
                            checked={optionalForm.days.indexOf(name) > -1}
                          />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth  variant="standard">
                    <InputLabel id='timeZone'>Franja horaria</InputLabel>
                    <Select
                      labelId='timeZone'
                      id='timeZonesOptions'
                      multiple
                      name='timeZones'
                      value={optionalForm.timeZones}
                      onChange={handleChange}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={MenuProps}
                    >
                      {TimeZoneData.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox
                            checked={optionalForm.timeZones.indexOf(name) > -1}
                          />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    id='subjecCode'
                    name='subject_code'
                    label='Código asignatura'
                    value={optionalForm.subject_code}
                    helperText=''
                    size='small'
                    variant="standard"
                    inputProps={{
                      inputMode: 'text',
                    }}
                    onChange={handleChange}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    id='subjectName'
                    name='subject_name'
                    label='Nombre asignatura'
                    value={optionalForm.subject_name}
                    helperText=''
                    size='small'
                    variant="standard"
                    inputProps={{
                      inputMode: 'text',
                    }}
                    onChange={handleChange}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    id='numGroups'
                    name='num_groups'
                    label='Número de grupos'
                    value={optionalForm.num_groups}
                    helperText=''
                    size='small'
                    variant="standard"
                    inputProps={{
                      maxLength: 3,
                      inputMode: 'numeric',
                    }}
                    onChange={handleChange}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    id='numQuotas'
                    name='num_quotas'
                    label='Número de cupos'
                    value={optionalForm.num_quotas}
                    helperText=''
                    size='small'
                    variant="standard"
                    inputProps={{
                      maxLength: 3,
                      inputMode: 'numeric',
                    }}
                    onChange={handleChange}
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      textAlign: { xs: 'center', tabletPortrait: 'left' },
                      width: { xs: '100%', tabletPortrait: 'auto' },
                    }}
                  >
                    <Button
                      variant='contained'
                      onClick={() => handleOnSearch()}
                    >
                      Buscar
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Box>

      {isLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}

      {showResults && (
        <Box>
          <Box
            display='flex'
            justifyContent='flex-end'
            gap='16px'
            sx={{ mb: 3 }}
          >
            {results.length > 0 && (
              <FormControl sx={{ width: { sm: '250px', xs: '100%' }, mb: 2 }} variant="standard">
                <InputLabel id='hideColumns'>Ocultar columnas</InputLabel>
                <Select
                  labelId='hideColumns'
                  id='hideColumnsSelect'
                  multiple
                  name='hideColumnsSelect'
                  value={hideColumns}
                  onChange={handleHideColumnsChange}
                  renderValue={(selected) => getHideColumnsSelected(selected)}
                  MenuProps={MenuProps}
                >
                  {hideColumnsData.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      <Checkbox
                        checked={hideColumns.some(
                          (column) => column === item.id
                        )}
                      />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
          {results.length > 0 && (
            <Box>
              <Box sx={{ height: 500, width: '100%' }}>
                <DataGrid
                  sx={{
                    '.MuiDataGrid-footerContainer': {
                      display: 'none',
                    },
                  }}
                  disableColumnFilter
                  rows={results}
                  columns={filteredHeaders}
                  slotProps={{
                    pagination: {
                      labelRowsPerPage: 'Filas por página',
                      labelDisplayedRows: ({ from, to, count }) =>
                        `${from}–${to} de ${count}`,
                    },
                  }}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: pageSize },
                    },
                  }}
                  pageSizeOptions={[
                    0,
                    pageSize,
                    20,
                    30,
                    40,
                    50,
                    60,
                    70,
                    80,
                    90,
                    100,
                  ]}
                  localeText={localeText}
                  oncolumnMenuOpen={() => {
                    setIsColumnMenuOpen(true);
                  }}
                  onColumnMenuClose={() => {
                    setIsColumnMenuOpen(false);
                  }}
                  className={isColumnMenuOpen ? 'fixed-column-menu' : ''}
                />
              </Box>
              <Typography
                variant='overline'
                gutterBottom
                sx={{ display: 'block' }}
              >
                cantidad de registros: {itemsCount}
              </Typography>
            </Box>
          )}

          {itemsCount > pageSize && (
            <Box
              sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 2 }}
            >
              <Pagination
                count={Math.ceil(itemsCount / pageSize)}
                color='primary'
                page={page}
                onChange={handlePageChange}
              />
            </Box>
          )}
        </Box>
      )}

      {showResults && !results.length && (
        <Box sx={{ textAlign: 'center' }}>
          <Alert severity='warning'>No se encontraron datos</Alert>
        </Box>
      )}
    </Box>
  );
}
