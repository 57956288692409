import {
  Alert,
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useNavigate, useSearchParams } from 'react-router-dom';
import createAxiosInstance from '../../helpers/AxiosConfig';

export const ResetPasswordForm = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorResponse, setErrorResponse] = useState({
    message: '',
    type: 'error',
  });
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  useEffect(() => {
    if (!token) {
        navigate('/', {
            replace: true,
        });
    }
  }, []);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorResponse({ message: '', type: 'error' });
    if (password === confirmPassword) {
      handleResetPassword();
    } else {
      setErrorResponse({
        message: 'Las contraseñas no coinciden',
        type: 'warning',
      });
    }
  };

  const handleResetPassword = async () => {
    setIsLoading(true);
    const request = {
      token,
      newPassword: password,
    };
    try {
      await createAxiosInstance().post(`/auth/reset-password`, request);
      navigate('/', {
        replace: true,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setErrorResponse({
        message:
          error.response &&
          (error.response.status === 400 || error.statusCode === 400)
            ? error.response.data.message &&
              Array.isArray(error.response.data?.message)
              ? error.response.data?.message.join(', ')
              : error.response.data?.message
            : 'Error del servidor, intentalo más tarde',
        type: 'error',
      });
    }
  };

  return (
    <Box>
      <Typography gutterBottom variant='h5' component='h2' sx={{ mb: 3 }}>
        Cambiar contraseña
      </Typography>
      <Box
        component='form'
        sx={{
          '& .MuiTextField-root, & .MuiFormControl-root': { mb: 3 },
        }}
        noValidate
        onSubmit={handleSubmit}
        autoComplete='off'
      >
        {errorResponse && errorResponse.message && (
          <Alert severity={errorResponse.type} sx={{ mb: 3 }}>
            {errorResponse.message}
          </Alert>
        )}
        <FormControl variant='outlined' fullWidth required>
          <InputLabel htmlFor='password' error={passwordTouched && (!password || password.length < 6)}>
            Contraseña
          </InputLabel>
          <OutlinedInput
            id='password'
            autoComplete='current-password'
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  aria-label='Alternar visibilidad de contraseña'
                  onClick={handleClickShowPassword}
                  edge='end'
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            value={password}
            error={passwordTouched && (!password || password.length < 6)}
            onChange={(e) => setPassword(e.target.value)}
            onBlur={() => setPasswordTouched(true)}
            label='Contraseña'
          />
        {passwordTouched && !password && (
            <FormHelperText error={passwordTouched && !password}>
              La contraseña es requerida
            </FormHelperText>
        )}

        {passwordTouched && password && password.length < 6 && (
            <FormHelperText error={true}>
              La contraseña debe ser de minimo 6 caracteres
            </FormHelperText>
        )}
        </FormControl>

        <FormControl variant='outlined' fullWidth required>
          <InputLabel
            htmlFor='confirmPassword'
            error={confirmPasswordTouched && (!confirmPassword || confirmPassword.length < 6)}
          >
            Confirmar contraseña
          </InputLabel>
          <OutlinedInput
            id='confirmPassword'
            autoComplete='current-password'
            type={showConfirmPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  aria-label='Alternar visibilidad de contraseña'
                  onClick={handleClickShowConfirmPassword}
                  edge='end'
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            value={confirmPassword}
            error={confirmPasswordTouched && (!confirmPassword || confirmPassword.length < 6)}
            onChange={(e) => setConfirmPassword(e.target.value)}
            onBlur={() => setConfirmPasswordTouched(true)}
            label='Confirmar contraseña'
          />
        {confirmPasswordTouched && !confirmPassword && (
            <FormHelperText error={confirmPasswordTouched && !confirmPassword}>
                La contraseña es requerida
            </FormHelperText>
        )}
        {confirmPasswordTouched && confirmPassword && confirmPassword.length < 6 && (
            <FormHelperText error={true}>
              La contraseña debe ser de minimo 6 caracteres
            </FormHelperText>
        )}
        </FormControl>

        <Box sx={{ textAlign: 'center' }}>
          <Button
            size='large'
            type='submit'
            variant='contained'
            disabled={(((!password || password.length < 6) || (!confirmPassword || confirmPassword.length < 6))) || isLoading}
          >
            {isLoading ? 'Cargando...' : 'Ingresar'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
