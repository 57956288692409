import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from '@mui/material';
import { useState } from 'react';
import { NavLink, Navigate } from 'react-router-dom';
import { IsValidShowResourceByRol } from '../router/ProtectedRoute';
import { Header } from './Header';
import { Footer } from './Footer';
import Roles from '../enums/Roles.enum';
import { useAuth } from '../providers/AuthProvider';
import { principalTheme } from '../theme/principalTheme';

const drawerWidth = 240;

export default function Shell ( { content } )
{

  const { window } = Window;
  const [ mobileOpen, setMobileOpen ] = useState( false );
  const [ isClosing, setIsClosing ] = useState( false );
  const auth = useAuth();

  const handleDrawerClose = () =>
  {
    setIsClosing( true );
    setMobileOpen( false );
  };

  const handleDrawerTransitionEnd = () =>
  {
    setIsClosing( false );
  };

  const handleDrawerToggle = () =>
  {
    if ( !isClosing )
    {
      setMobileOpen( !mobileOpen );
    }
  };

  const logout = () =>
  {
    auth.resetUserData();
    <Navigate to="/login" />
  }

  const drawer = (
    <div>
      <Toolbar />
      { auth.getUser() && auth.getUser().name &&
        <>
          <Typography variant="h6" component="div" sx={ { p: 2, } }>
            { auth.getUser().name } { auth.getUser()?.lastname }
          </Typography>
          {auth.getUser()?.role !== Roles.Admin &&
            <>
              <Typography variant="body2" sx={ { pl: 2, pr: 2 } }>
                Sede: {auth.getUser().sede.name}
              </Typography>
              <Typography variant="body2" sx={ { pl: 2, pr: 2 } }>
                {auth.getUser().faculty.name}
            </Typography>
            </>
          }
        </>
      }
      <List sx={{
        '.MuiListItem-root a': {
          color: principalTheme.palette.primary.main
        }
      }}>
        { IsValidShowResourceByRol( [] ) &&
          <ListItem key={ 'dashboardKey' } disablePadding>
            <NavLink to={ '/dashboard' } style={ { textDecoration: 'none', width: '100%' } }>
              <ListItemButton>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary='Dashboard' />
              </ListItemButton>
            </NavLink>
        </ListItem>
        }
        <ListItem key={ 'logoautKey' } disablePadding>
          <ListItemButton onClick={ () => logout() }>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary='Cerrar sesión' />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{
        display: 'flex',
        height: '100vh',
        flexDirection: 'column',
      }}>
      <Header />
      <Box sx={ { flex: '1 0 auto', display: { tabletPortrait: 'flex' } } }>
        <AppBar
          position="absolute"
          component="div"
          sx={ {
            display: { tabletPortrait: 'none' },
            backgroundColor: '#666',
            zIndex: 3,
            width: { tabletPortrait: `calc(100% - ${ drawerWidth }px)` },
            top: '40px',
            height: '56px',
            ml: { tabletPortrait: `${ drawerWidth }px` },
          } }
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={ handleDrawerToggle }
              sx={ { mr: 2, display: { tabletPortrait: 'none' } } }
            >
              <MenuIcon />
            </IconButton>
            {/* <Typography variant="h6" noWrap component="div">
              Responsive drawer
            </Typography> */}
          </Toolbar>
        </AppBar>

        <Box
          component="nav"
          sx={ { width: { tabletPortrait: drawerWidth }, flexShrink: { tabletPortrait: 0 } } }
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */ }
          <Drawer
            container={ container }
            variant="temporary"
            open={ mobileOpen }
            onTransitionEnd={ handleDrawerTransitionEnd }
            onClose={ handleDrawerClose }
            ModalProps={ {
              keepMounted: true, // Better open performance on mobile.
            } }
            sx={ {
              display: { xs: 'block', tabletPortrait: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            } }
          >
            { drawer }
          </Drawer>
          <Drawer
            variant="permanent"
            sx={ {
              display: { xs: 'none', tabletPortrait: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, position: 'relative', 'z-index': 1 },
            } }
            open
          >
            { drawer }
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={ { flexGrow: 1, p: 3, width: { tabletPortrait: `calc(100% - ${ drawerWidth }px)` } } }>
          <Toolbar sx={ { display: { tabletPortrait: 'none' } } } />
          { content }
        </Box>
      </Box>
      <Footer />
    </Box>
  )
}
