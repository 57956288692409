export const localeText = {
  // Texts for column headers, labels, etc.
  columnHeaderFiltersLabel: 'Filtros',
  columnMenuLabel: 'Menú',
  columnMenuUnsort: 'Desordenar',
  columnMenuSortAsc: 'Ordenar ascendente',
  columnMenuSortDesc: 'Ordenar descendente',
  columnMenuFilter: 'Filtrar',
  columnMenuHideColumn: 'Ocultar columna',
  columnMenuShowColumns: 'Mostrar columnas',
  footerRowSelected: (count) =>
    count !== 1
      ? `${count.toLocaleString()} filas seleccionadas`
      : `${count.toLocaleString()} fila seleccionada`,
  footerTotalRows: 'Total de filas:',
  // Texts for pagination
  footerPaginationRowsPerPage: 'Filas por página:',
  footerPaginationLabelDisplayedRows: ({ from, to, count }) => 
    `${from} - ${to} de ${count !== -1 ? count.toLocaleString() : `más de ${to.toLocaleString()}`}`,
  // Texts for other components
  toolbarExport: 'Exportar',
  toolbarExportCSV: 'Descargar como CSV',
  toolbarExportPrint: 'Imprimir',
  toolbarDensity: 'Densidad',
  toolbarDensityLabel: 'Densidad',
  toolbarDensityCompact: 'Compacta',
  toolbarDensityStandard: 'Estándar',
  toolbarDensityComfortable: 'Cómoda',
  // Other texts
  noRowsLabel: 'No hay filas',
  noResultsOverlayLabel: 'Sin resultados',
  errorOverlayDefaultLabel: 'Ha ocurrido un error',
  filterPanelAddFilter: 'Añadir filtro',
  filterPanelDeleteIconLabel: 'Eliminar',
  filterPanelOperators: 'Operadores',
  filterPanelOperatorAnd: 'Y',
  filterPanelOperatorOr: 'O',
  filterPanelColumns: 'Columnas',
  filterPanelInputLabel: 'Valor',
  filterPanelInputPlaceholder: 'Valor de filtro',
  filterOperatorContains: 'contiene',
  filterOperatorEquals: 'es igual a',
  filterOperatorStartsWith: 'empieza con',
  filterOperatorEndsWith: 'termina con',
  filterOperatorIs: 'es',
  filterOperatorNot: 'no es',
  filterOperatorAfter: 'después',
  filterOperatorOnOrAfter: 'en o después',
  filterOperatorBefore: 'antes',
  filterOperatorOnOrBefore: 'en o antes',
  filterPanelValueInputPlaceholder: 'Valor',
  columnMenuManageColumns: 'Gestionar columnas',
  columnsManagementSearchTitle: 'Buscar',
  columnsManagementShowHideAllText: 'Mostrar/Ocultar todo',
  columnsManagementReset: 'Reiniciar',
  filterOperatorIsEmpty: 'está vacío',           // Added translation
  filterOperatorIsNotEmpty: 'no está vacío',     // Added translation
  filterOperatorIsAnyOf: 'es cualquiera de',     // Added translation
};
