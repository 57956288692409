export const DaysData = [
  'Lunes',
  'Martes',
  'Miércoles',
  'Jueves',
  'Viernes',
  'Sábado',
  'Domingo',
];

export const LevelData = [
  {
    value: 'pregrado',
    label: 'Pregrado',
  },
  {
    value: 'Postgrados y másteres',
    label: 'Postgrados y másteres',
  },
];

export const TimeZoneData = [
  '8:00',
  '9:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
];

export const hideColumnsData = [
  {
    name: 'Código',
    id: 'CODIGO_ASIGNATURA',
  },
  {
    name: 'Nombre asignatura',
    id: 'NOMBREASIGNATURA',
  },
  {
    name: 'Creditos',
    id: 'NUM_CREDITOS',
  },
  {
    name: 'Tipologia',
    id: 'TIPOLOGIA',
  },
  {
    name: 'Numero de grupos',
    id: 'NUMERODEGRUPOS',
  },
  {
    name: 'Numero de cupos',
    id: 'NUMERODECUPOS',
  },
];

export const filesNameData = {
  fileName1: 'PRE_CARGAACTIVIDADESGRUPOS',
  fileName2: 'PRE_ASIGNATURASLIBREELECCION',
  fileName3: 'PRE_HOJA2ASIGNATURAS',
  fileName4: 'PRE_HOJA3ASIGNATURAS',
  fileName5: 'PRE_CARGAPLANESTUDIODISPONBILE',
  fileName6: 'PRE_OFERTADAVSGRUPOS',
  fileName7: 'REP_HORARIOSGRUPOS',
};
