import axios from 'axios';

const createAxiosInstance = () => {

  const isHttps = window.location.protocol === 'https:';

  const baseURL = isHttps
    ? 'https://buscadorsgadinara.com/api/v1'
    : 'http://54.205.180.2/api/v1';

  const instance = axios.create({
    baseURL: baseURL,
    timeout: 500000,
  });

  const token = '';
  instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  return instance;
};

export default createAxiosInstance;
