import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../providers/AuthProvider';
import Roles from '../enums/Roles.enum';

export const ProtectedRoute = () => {
  const auth = useAuth();
  return auth.isAuthenticated() ? <Outlet /> : <Navigate to="/login" />;
};

export const ProtectedRouteByRole = ({ rolesNotAllowed }) => {
  const auth = useAuth();
  return rolesNotAllowed.some(
    (role) => role.toLowerCase() === auth?.getUser()?.role?.toLowerCase()
  ) ? (
    <Navigate to={auth.getUser().role === Roles.Admin ? '/dashboard' : '/'} />
  ) : (
    <Outlet />
  );
};


export const IsValidShowResourceByRol = ( rolesNotAllowed ) =>
{
  const auth = useAuth();
  const isValid = !(rolesNotAllowed.some( role => role.toLowerCase() === auth.getUser().role.toLowerCase() ));
  return isValid;
}