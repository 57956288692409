import {
  Alert,
    Box, Button, Card, CardActions, CardContent, CircularProgress, Grid, Input, List, ListItem, ListItemText, styled, Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import createAxiosInstance from '../../helpers/AxiosConfig';
import { filesNameData } from '../data/courseSearchData';
import { io } from 'socket.io-client';
import { useAuth } from '../../providers/AuthProvider';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


export const Dashboard = () => {

  const auth = useAuth();

  const [isLoadingToSendFile, setIsLoadingToSendFile] = useState(false);
  const [appAlert, setAppAlert] = useState({
    message: '',
    type: 'error',
    errors: [],
  });
  const [files, setFiles] = useState([]);

  const SOCKET_URL = 'http://localhost';

  useEffect(() => {
    // initSocket();
  }, []);

  const handleFileProcessed = (message) => {
    alert(message);
  };

  const initSocket = () => {
    const clientName = auth.getUser().id;
    const socket = io(SOCKET_URL);

    // Conexión al socket y registro de eventos
    socket.on('connect', () => {
      if (clientName) {
        socket.emit('register', socket.id, clientName);
      }
      socket.on('fileProcessed', handleFileProcessed);
    });

    // Limpiar al desmontar el componente
    return () => {
      socket.off('fileProcessed', handleFileProcessed);
      socket.disconnect();
    };
  }

  const initAppAlert = () => {
    setAppAlert({
      message: '',
      type: 'error',
      errors: [],
    });
  };

  const handleFileChange = (event, fileName) => {
    initAppAlert();
    const finalFileName = `${fileName}.csv`;
    if (!event.target.files.length) {
      const filter = Array.from(files).filter(file => file.name !== finalFileName);
      setFiles(filter);
      return;
    }
    const file = event.target.files[0];
    if (file.type === 'text/csv') {
      const modifiedFiles = Array.from(event.target.files).map(file => {
        return new File([file], finalFileName, {
          type: file.type,
          lastModified: file.lastModified,
        });
      });

      const finalFile = modifiedFiles[0];

      const filter = Array.from(files).filter(file => file.name !== fileName);
      setFiles([...filter, finalFile]);
    } else {
      setAppAlert({
        message: 'Solo se permite formato csv',
        type: 'warning',
        errors: [],
      });
    }
  };

  const handleSendFile = async () => {
    initAppAlert();
    setIsLoadingToSendFile(true);
    try {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append(`files`, file);
      });
      await createAxiosInstance().post(`/carga-archivos?name=TBD&id=${auth.getUser().id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        maxContentLength: Infinity, // Asegúrate de permitir contenido grande
        maxRedirects: 0
      });
      setAppAlert({
        message: 'Sus archivos se estan procesando en este momento...',
        errors: [],
        type: 'success',
      });
      setFiles([]);
      setIsLoadingToSendFile(false);
    } catch (error) {
      setIsLoadingToSendFile(false);
      setAppAlert({
        message:
          !error.response || (error.response.status === 500 || error.statusCode === 500)
            ? 'Error del servidor, intentalo más tarde'
            : 'Error al subir los archivos',
        errors:
          error.response && error.response.data && error.response.data.message
            ? Array.isArray(error.response.data.message)
              ? error.response.data.message
              : [error.response.data.message]
            : [],
      });
    }
  };

  return (
    <Box>
      <Typography variant='h4' component='h2' sx={{ mb: 4 }}>
        Importador de cursos
      </Typography>
      {appAlert && appAlert.message && (
        <Alert
          severity={appAlert.type ?? 'error'}
          sx={{ mb: 3, textAlign: 'left' }}
        >
          {appAlert.message}
          {appAlert.errors.length > 0 && (
            <List>
              {appAlert.errors.map((error, index) => (
                <ListItem key={index} sx={{ p: 0 }}>
                  <ListItemText primary={error} />
                </ListItem>
              ))}
            </List>
          )}
        </Alert>
      )}
      <Grid container spacing={4} >
        <Grid item xs={12} sm={6} lg={3}>
          { files?.length > 0 && Array.from(files).some(file => file.name === `${filesNameData.fileName1}.csv`) &&
            <Alert severity="success" sx={{mb: 2}}>Archivo cargado</Alert>
          }
          <Card >
            <CardContent>
              <Typography gutterBottom variant='h5' component='div'>
                Consolidado de actividades y grupos
              </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: 'center' }}>
              <Button
                component='label'
                role={undefined}
                variant='contained'
                tabIndex={-1}
                onChange={(e) => handleFileChange(e, filesNameData.fileName1)}
                startIcon={<CloudUploadIcon />}
              >
                Cargar
                <VisuallyHiddenInput type='file' />
              </Button>
              
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          { files?.length > 0 && Array.from(files).some(file => file.name === `${filesNameData.fileName2}.csv`) &&
            <Alert severity="success" sx={{mb: 2}}>Archivo cargado</Alert>
          }
          <Card>
            <CardContent>
              <Typography gutterBottom variant='h5' component='div'>
                Consolidado de asignaturas de libre elección
              </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: 'center' }}>
              <Button
                component='label'
                role={undefined}
                variant='contained'
                tabIndex={-1}
                onChange={(e) => handleFileChange(e, `${filesNameData.fileName2}`)}
                startIcon={<CloudUploadIcon />}
              >
                Cargar
                <VisuallyHiddenInput type='file' />
              </Button>
              
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          { files?.length > 0 && Array.from(files).some(file => file.name === `${filesNameData.fileName3}.csv`) &&
            <Alert severity="success" sx={{mb: 2}}>Archivo cargado</Alert>
          }
          <Card>
            <CardContent>
              <Typography gutterBottom variant='h5' component='div'>
                Consolidado de hoja 2 de parametrización de asignaturas
              </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: 'center' }}>
              <Button
                component='label'
                role={undefined}
                variant='contained'
                tabIndex={-1}
                onChange={(e) => handleFileChange(e, `${filesNameData.fileName3}`)}
                startIcon={<CloudUploadIcon />}
              >
                Cargar
                <VisuallyHiddenInput type='file' />
              </Button>
              
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          { files?.length > 0 && Array.from(files).some(file => file.name === `${filesNameData.fileName4}.csv`) &&
            <Alert severity="success" sx={{mb: 2}}>Archivo cargado</Alert>
          }
          <Card>
            <CardContent>
              <Typography gutterBottom variant='h5' component='div'>
                Consolidado de hoja 3 de parametrización de asignaturas
              </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: 'center' }}>
              <Button
                component='label'
                role={undefined}
                variant='contained'
                tabIndex={-1}
                onChange={(e) => handleFileChange(e, `${filesNameData.fileName4}`)}
                startIcon={<CloudUploadIcon />}
              >
                Cargar
                <VisuallyHiddenInput type='file' />
              </Button>
              
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          { files?.length > 0 && Array.from(files).some(file => file.name === `${filesNameData.fileName5}.csv`) &&
            <Alert severity="success" sx={{mb: 2}}>Archivo cargado</Alert>
          }
          <Card>
            <CardContent>
              <Typography gutterBottom variant='h5' component='div'>
                Consolidado de planes de estudios
              </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: 'center' }}>
              <Button
                component='label'
                role={undefined}
                variant='contained'
                tabIndex={-1}
                onChange={(e) => handleFileChange(e, `${filesNameData.fileName5}`)}
                startIcon={<CloudUploadIcon />}
              >
                Cargar
                <VisuallyHiddenInput type='file' />
              </Button>
              
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          { files?.length > 0 && Array.from(files).some(file => file.name === `${filesNameData.fileName6}.csv`) &&
            <Alert severity="success" sx={{mb: 2}}>Archivo cargado</Alert>
          }
          <Card>
            <CardContent>
              <Typography gutterBottom variant='h5' component='div'>
                Consolidado de ofertadas y grupos
              </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: 'center' }}>
              <Button
                component='label'
                role={undefined}
                variant='contained'
                tabIndex={-1}
                onChange={(e) => handleFileChange(e, `${filesNameData.fileName6}`)}
                startIcon={<CloudUploadIcon />}
              >
                Cargar
                <VisuallyHiddenInput type='file' />
              </Button>
              
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          { files?.length > 0 && Array.from(files).some(file => file.name === `${filesNameData.fileName7}.csv`) &&
            <Alert severity="success" sx={{mb: 2}}>Archivo cargado</Alert>
          }
          <Card>
            <CardContent>
              <Typography gutterBottom variant='h5' component='div'>
                Consolidado de horarios vs grupos
              </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: 'center' }}>
              <Button
                component='label'
                role={undefined}
                variant='contained'
                tabIndex={-1}
                onChange={(e) => handleFileChange(e, `${filesNameData.fileName7}`)}
                startIcon={<CloudUploadIcon />}
              >
                Cargar
                <VisuallyHiddenInput type='file' />
              </Button>
              
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'center'}} alignItems={'center'}>
          <Button
            variant='contained'
            color='success'
            disabled={!files?.length || files?.length < 7 || isLoadingToSendFile}
            onClick={handleSendFile}
          >
            {isLoadingToSendFile ? 'Procesando...' : 'Cargar archivos'}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
