import { Box, Container } from '@mui/material';
import { Header } from './Header';
import { Footer } from './Footer';

export const PublicLayout = ( { content } ) => {
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        flexDirection: 'column',
      }}
    >
      <Header />
      <Box
        sx={{
          flex: '1 0 auto',
          // display: 'flex',
          mt: 8,
          mb: 6,
          // flexDirection: 'column',
          // justifyContent: 'center',
        }}
      >
        <Container maxWidth='xl'>
          {content}
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};
